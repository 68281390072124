<template>
  <div class="main">
    <BigTitleBner title="BRAND PROCESS" :url="`${this.$imgUrl}img_c_1.png`" />
    <div class="main-con">
      <div class="containerView">
        <div
          class="article-title text_center text_bold"
          v-animate="'animated fadeInUp'"
        >
          <h2 class="size_48">BRAND PROCESS</h2>
          <h3 class="size_24" v-html="$t('processText.text1')"></h3>
        </div>
        <div class="article-con size_20">
          <article>
            <div class="view_flex">
              <div class="view_flex_csb illustration_text">
                <p v-animate="'animated fadeInUp'">
                  <img
                    class="illustration_1"
                    :src="`${this.$imgUrl}img_c_2.png`"
                  />
                  <span
                    v-html="$t('processText.text2')"
                    v-animate="'animated fadeInUp'"
                  ></span>
                </p>
              </div>
              <div style="clear: both"></div>
            </div>
            <p
              v-html="$t('processText.text3')"
              v-animate="'animated fadeInUp'"
            ></p>
            <div class="view_flex illustration_box1">
              <div class="illustration_box1_l" v-animate="'animated fadeInUp'">
                <img :src="`${this.$imgUrl}img_c_3.png`" />
              </div>
              <div class="illustration_box1_r view_flex_sb">
                <div class="box1_r_l view_flex_csb">
                  <div v-animate="'animated fadeInUp'">
                    <img :src="`${this.$imgUrl}img_c_4.png`" />
                  </div>
                  <div v-animate="'animated fadeInUp'">
                    <img :src="`${this.$imgUrl}img_c_5.png`" />
                  </div>
                </div>
                <div class="box1_r_r view_flex_csb">
                  <div v-animate="'animated fadeInUp'">
                    <img :src="`${this.$imgUrl}img_c_6.png`" />
                  </div>
                  <div v-animate="'animated fadeInUp'">
                    <img :src="`${this.$imgUrl}img_c_7.png`" />
                  </div>
                </div>
              </div>
            </div>
            <p
              v-html="$t('processText.text4')"
              v-animate="'animated fadeInUp'"
            ></p>
            <div class="illustration_box2 view_flex">
              <div class="illustration_box2_l view_flex_sb">
                <div class="l-item1" v-animate="'animated fadeInUp'">
                  <img :src="`${this.$imgUrl}img_c_8.png`" />
                </div>
                <div class="view_flex_csb">
                  <div class="l-item2" v-animate="'animated fadeInUp'">
                    <img :src="`${this.$imgUrl}img_c_9.png`" />
                  </div>
                  <div class="l-item3" v-animate="'animated fadeInUp'">
                    <img :src="`${this.$imgUrl}img_c_10.png`" />
                  </div>
                </div>
              </div>
              <div class="illustration_box2_r view_flex_csb">
                <div class="r-item1" v-animate="'animated fadeInUp'">
                  <img :src="`${this.$imgUrl}img_c_11.png`" />
                </div>
                <div class="view_flex_sb">
                  <div class="r-item2" v-animate="'animated fadeInUp'">
                    <img :src="`${this.$imgUrl}img_c_12.png`" />
                  </div>
                  <div class="r-item3" v-animate="'animated fadeInUp'">
                    <img :src="`${this.$imgUrl}img_c_13.png`" />
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BigTitleBner from "@/components/BigTitleBner";
export default {
  name: "process",
  data() {
    return {};
  },
  components: {
    BigTitleBner,
  },
};
</script>
<style lang="scss" scoped>
.main-con {
  padding-top: 100px;
  padding-bottom: 100px;
  .containerView {
    padding: 0 170px;
    box-sizing: border-box;
  }
}

.article-title {
  margin-bottom: 50px;
  > h2 {
    margin-bottom: 10px;
  }
}

.article-con {
  > article {
    p {
      text-align: justify;
    }
    > p {
      margin-bottom: 50px;
    }
    > div {
      margin-bottom: 50px;
    }
  }
}

.illustration_text {
  flex: 1;
  width: 0;
}

.illustration_1 {
  width: 354px;
  height: 236px;
  background: #eee;
  margin-left: 50px;
  float: right;
}

.illustration_box1 {
  height: 236px;
  > .illustration_box1_l {
    width: 354px;
    height: 100%0;
    background: #eee;
    margin-right: 29px;
  }
  > .illustration_box1_r {
    flex: 1;
    width: 0;
    height: 100%;
    .box1_r_l,
    .box1_r_r {
      height: 100%;
      > div {
        height: 112px;
        background: #eee;
      }
    }
    .box1_r_l {
      width: 240px;
    }
    .box1_r_r {
      width: 210px;
    }
  }
}

.illustration_box2 {
  height: 240px;
  .illustration_box2_l,
  .illustration_box2_r {
    height: 100%;
  }

  .illustration_box2_l {
    flex: 1;
    width: 0;
    .l-item1 {
      width: 180px;
      height: 240px;
      background: #eee;
    }
    .l-item2 {
      width: 200px;
      height: 160px;
      background: #eee;
    }
    .l-item3 {
      width: 200px;
      height: 70px;
      background: #eee;
    }
  }

  .illustration_box2_r {
    width: 446px;
    margin-left: 10px;
    .r-item1 {
      height: 80px;
      background: #eee;
    }
    .r-item2 {
      width: 223px;
      height: 150px;
      background: #eee;
    }
    .r-item3 {
      width: 213px;
      height: 150px;
      background: #eee;
    }
  }
}
</style>
